<template>
  <div class="layout_common merchant_replace">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <selectComp
              :hval="haveCode"
              :data="haveCodeList"
              @change="haveCodeChange"
              placeholder="有无快递单号"
            />
            <inputComp placeholder="工单编号" :hval="code" @blur="codeBlur" />
            <inputComp
              :hval="orderCode"
              @blur="orderCodeBlur"
              placeholder="订单编号"
            />
            <inputComp
              placeholder="快递单号"
              :hval="expressCode"
              @blur="expressCodeBlur"
            />
            <inputComp
              :hval="registrantContacterMobile"
              @blur="registrantContacterMobileBlur"
              placeholder="用户注册手机号"
            />
            <inputComp
              :hval="repairContacter"
              @blur="repairContacterBlur"
              placeholder="报修人"
            />
            <inputComp
              :hval="contacterMobile"
              @blur="contacterMobileBlur"
              placeholder="报修人手机号"
            />
            <selectComp
              v-if="dataAuthority"
              :hval="expressType"
              :data="expressTypeList"
              @change="expressTypeChange"
              placeholder="快递方向"
            />
            <selectComp
              :hval="status"
              :data="statusList"
              @change="statusChange"
              placeholder="状态"
            />
            <selectComp
              :hval="abnormal"
              :data="abnormalList"
              @change="abnormalChange"
              placeholder="异常状态"
            />
            <handleUser
              placeholder="客服处理人"
              :hval="serviceId"
              businessRole="service"
              @change="serviceIdChange"
            />
            <handleUser
              placeholder="售后处理人"
              :hval="handleId"
              businessRole="aftermarket"
              @change="handleIdChange"
            />
            <handleUser
              placeholder="调度处理人"
              :hval="dispatchId"
              businessRole="dispatch"
              @change="dispatchIdChange"
            />
            <merchant :hval="storeName" @storeNameChange="storeNameChange" />
            <dateComp
              startPlaceholder="工单开始日期"
              endPlaceholder="工单结束日期"
              :hval="date"
              @change="dateChange"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="exportData" v-permission="'导出列表'"
              >导出列表</btnComp
            >
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column slot="code" label="工单编号">
            <template v-slot="scope">
               <div style="color:#385BFF;cursor:pointer" @click="codeClick(scope.row)">
                 {{scope.row.code}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            able-column
            slot="expressCode"
            label="快递单号"
            width="180"
          >
            <template v-slot="scope">
              <a target="_blank" :href="expressCodeURL(scope.row)">
                {{ scope.row.expressCompanyName }} {{ scope.row.expressCode }}
              </a>
            </template>
          </el-table-column>
          <el-table-column slot="abnormal" label="异常原因">
            <template v-slot="scope">
              <!-- <el-tooltip
                v-if="scope.row.abnormalReason"
                popper-class="abnormal"
                :content="scope.row.abnormalReason"
                placement="top"
                effect="light"
              >
                <span :style="{ color: scope.row.abnormal ? 'red' : '' }">{{
                  scope.row.abnormal ? '异常' : '-'
                }}</span>
              </el-tooltip> -->
              <span
                :style="{ color: scope.row.abnormal ? 'red' : '' }"
                >{{ scope.row.abnormal ? '异常' : '-' }}</span
              >
              <div style="color:#ff7f00">{{scope.row.abnormalReason}}</div>
            </template>
          </el-table-column>
          <el-table-column slot="handlerInfo" label="处理人" width="140">
            <template v-slot="scope">
              <ul>
                <li v-if="scope.row.serviceName">
                  客服:{{scope.row.serviceName}}
                </li>
                <li v-if="scope.row.remoteName">
                  售后:{{
                    scope.row.remoteName +
                      (scope.row.remoteNum > 1 ? '/' + scope.row.remoteNum : '') ||
                      '-'
                  }}
                </li>
                <li v-if="scope.row.storeHandleName">
                  商家:{{
                    scope.row.storeHandleName +
                      (scope.row.storeNum > 1 ? '/' + scope.row.storeNum : '') ||
                      '-'
                  }}
                </li>
                <li v-if="scope.row.dispatchName">
                  调度:{{scope.row.dispatchName}}
                </li>
              </ul>
            </template>
          </el-table-column>
           <el-table-column slot="typeName" label="快递方向" v-if="dataAuthority">
            <template v-slot="scope">
              {{ scope.row.typeName }}
            </template>
          </el-table-column>
           <el-table-column slot="remark" label="备注">
            <template v-slot="scope">
              <div>平台:{{ scope.row.remark || '-' }}</div>
              <div>商家:{{ scope.row.storeRemark || '-'  }}</div>
              <div>客户:{{ scope.row.customerRemark || '-'  }}</div>
            </template>
          </el-table-column>
          <el-table-column
            slot="operate"
            label="操作"
            fixed="right"
            width="140"
          >
            <template v-slot="scope">
              <btnComp
                btnType="text"
                style="margin-left:10px"
                @click="edit(scope.row)"
                v-permission="'编辑'"
                >编辑</btnComp
              >
              <btnComp
                btnType="text"
                v-if="!scope.row.expressCode"
                style="color:red"
                @click="del(scope.row,'del')"
                v-permission="'删除'"
                >删除</btnComp
              >
              <btnComp
                btnType="text"
                v-if="scope.row.expressCode"
                style="color:red"
                v-permission="'取消'"
                @click="cancel(scope.row,'cancel')"
                >取消</btnComp
              >
              <btnComp
                btnType="text"
                v-if="scope.row.status !== 3"
                v-permission="'快递刷新'"
                @click="refresh(scope.row)"
                >快递刷新</btnComp
              >
              <btnComp
                btnType="text"
                v-if="scope.row.status !== 3"
                v-permission="'快递已收'"
                @click="sign(scope.row)"
                >快递已收</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <express
      :isShow="expressShow"
      :info="info"
      dialogType="edit"
      @close="close"
    />
    <!-- 手动签收 -->
    <sign :isShow="signShow" :info="info" @close="close" />
    <!-- 详情弹窗 -->
    <drawerDetail
      @close="close"
    />
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="hintSure">
      <p>确定{{hintType==='del'?'删除':'取消'}}吗？</p>
    </dialogComp>
  </div>
</template>
<script>
import handleUser from '@/views/components/handleUser'
import merchant from '@/views/components/merchant'
import drawerDetail from '@/views/work-order/all-order/detail'
import express from '@/views/work-order/all-order/express-info/express'
import sign from '@/views/work-order/all-order/express-info/sign'
import { expressList, expressExport, expressDel, expressCancel, expressRefresh } from '@/api'
import { mapMutations, mapState } from 'vuex'
export default {
  props: {
    // 类别，1：商家寄给客户，2：客户寄回商家
    type: {
      type: Number,
      default: 1
    },
    // 是否加数据权限  我的快递传true
    dataAuthority: {
      type: Boolean,
      default: false
    }
  },
  components: {
    handleUser,
    express,
    sign,
    merchant,
    drawerDetail
  },
  data () {
    return {
      loading: false,
      expressShow: false,
      hintShow: false,
      signShow: false,
      hintType: '', // 区分是删除还是取消
      info: {},
      selectIds: [],
      haveCodeList: [
        { label: '有快递单号', value: 1 },
        { label: '无快递单号', value: 2 }
      ],
      abnormalList: [
        { label: '正常', value: false },
        { label: '异常', value: true }
      ],
      expressTypeList: [
        { label: '客户收', value: 1 },
        { label: '客户发', value: 2 }
      ],
      expressType: null,
      haveCode: null,
      code: '',
      orderCode: '',
      expressCode: '',
      registrantContacterMobile: '',
      repairContacter: '',
      contacterMobile: '',
      status: '',
      abnormal: null,
      serviceId: '',
      handleId: '',
      dispatchId: '',
      storeName: '',
      date: [],
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      theadName: [
        // { prop: 'storeRemark', label: '商家备注' },
        { slot: 'remark' },
        { prop: 'statusName', label: '快递状态' },
        { slot: 'abnormal' },
        { slot: 'code' },
        { prop: 'repairContacter', label: '报修人' },
        { prop: 'storeName', label: '商家' },
        { slot: 'handlerInfo' },
        { slot: 'typeName' },
        { slot: 'expressCode' },
        // { prop: 'remark', label: '平台备注' },
        { slot: 'operate' }
      ]
    }
  },
  computed: {
    ...mapState(['IndexInfo']),
    statusList () {
      return this.dataAuthority ? [
        { label: '待发货', value: 0 },
        { label: '揽件中', value: 1 },
        { label: '已发货', value: 2 },
        { label: '派送失败', value: 4 },
        { label: '疑难件', value: 5 },
        { label: '退件签收', value: 6 },
        { label: '快递取消', value: 7 }
      ] : [
        { label: '待发货', value: 0 },
        { label: '揽件中', value: 1 },
        { label: '已发货', value: 2 },
        { label: '已签收', value: 3 },
        { label: '派送失败', value: 4 },
        { label: '疑难件', value: 5 },
        { label: '退件签收', value: 6 },
        { label: '快递取消', value: 7 }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW', 'SET_INDEX_INFO']),
    getList () {
      // 类别，1：商家寄给客户，2：客户寄回商家
      this.params = {
        dataAuthority: this.dataAuthority,
        type: this.dataAuthority ? this.expressType : this.type,
        curPage: this.curPage,
        pageSize: this.pageSize,
        haveCode: this.haveCode,
        code: this.code,
        orderCode: this.orderCode,
        expressCode: this.expressCode,
        registrantContacterMobile: this.registrantContacterMobile,
        repairContacter: this.repairContacter,
        contacterMobile: this.contacterMobile,
        status: this.status,
        abnormal: this.abnormal,
        handleId: this.handleId,
        serviceId: this.serviceId,
        dispatchId: this.dispatchId,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      expressList(this.params).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    // 点击工单号弹窗
    codeClick (info) {
      const { orderId, categoryId, orderCode } = info
      this.SET_DRAWER_SHOW(true)
      this.SET_INDEX_INFO({ id: orderId, categoryId, orderCode })
    },
    // 根据不同的快递公司查询单号
    expressCodeURL (row) {
      const { expressCompany, expressCode } = row
      let url = ''
      // 快递公司，1：顺丰，2：德邦，3：申通，4：京东
      switch (expressCompany) {
        case 1:
          url = `https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/${expressCode}`
          break
        case 2:
          url = `https://www.deppon.com/track/ordertrack?orderNumList=%5B%22${expressCode}%22%5D`
          break
        // case 3:
        //   url = `https://www.baidu.com/s?ie=utf8&oe=utf8&wd=${expressCode}&tn=98010089_dg&ch=3`
        //   break
        default:
          url = `https://www.baidu.com/s?ie=utf8&oe=utf8&wd=${expressCode}`
          break
      }
      return url
    },
    // 快递方向
    expressTypeChange (val) {
      this.expressType = val
    },
    haveCodeChange (val) {
      this.haveCode = val
    },
    codeBlur (val) {
      this.code = val
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    expressCodeBlur (val) {
      this.expressCode = val
    },
    registrantContacterMobileBlur (val) {
      this.registrantContacterMobile = val
    },
    repairContacterBlur (val) {
      this.repairContacter = val
    },
    contacterMobileBlur (val) {
      this.contacterMobile = val
    },
    statusChange (val) {
      this.status = val
    },
    abnormalChange (val) {
      this.abnormal = val
    },
    serviceIdChange (val) {
      this.serviceId = val
    },
    handleIdChange (val) {
      this.handleId = val
    },
    dispatchIdChange (val) {
      this.dispatchId = val
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    // 手动签收
    sign (info) {
      this.signShow = true
      this.info = info
    },
    // 导出
    exportData () {
      expressExport(this.params)
      this.$message({
        message: '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    edit (row) {
      this.info = row
      this.expressShow = true
    },
    // 快递刷新
    refresh ({ expressCode }) {
      expressRefresh({ expressCode }).then(_ => {
        this.$message.success('刷新成功')
        this.getList()
      })
    },
    // 删除
    del (row, type) {
      this.info = row
      this.hintType = type
      this.hintShow = true
    },
    // 取消
    cancel (row, type) {
      this.info = row
      this.hintType = type
      this.hintShow = true
    },
    hintSure () {
      if (this.hintType === 'del') {
        // 删除
        expressDel({ id: this.info.id }).then(res => {
          this.$message.success('删除成功')
          this.close()
        })
      } else {
        // 取消
        expressCancel({ id: this.info.id }).then(res => {
          this.$message.success('取消成功')
          this.close()
        })
      }
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    close () {
      this.expressShow = false
      this.hintShow = false
      this.signShow = false
      this.SET_DRAWER_SHOW(false)
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.haveCode = null
      this.code = ''
      this.orderCode = ''
      this.registrantContacterMobile = ''
      this.repairContacter = ''
      this.contacterMobile = ''
      this.expressCode = ''
      this.status = ''
      this.handleId = ''
      this.serviceId = ''
      this.dispatchId = ''
      this.abnormal = null
      this.startTime = ''
      this.endTime = ''
      this.storeId = ''
      this.storeName = ''
      this.expressType = null
      this.date = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang="scss">
.el-tooltip__popper.abnormal {
  color: #ff7f00;
}
</style>
